export const LOCALE_MAP = {
  en: "en-US",
  th: "th-TH",
  "zh-TW": "zh-TW",
  "zh-CN": "zh-CN",
  vi: "vi-VN",
  ko: "ko-KR",
  ja: "ja-JP",
  "ja-KS": "ja-KS",
  ar: "ar-AR",
  bn: "bn-BN",
  my: "my-MY",
  fr: "fr-FR",
  hi: "hi-HI",
  id: "id-ID",
  fa: "fa-FA",
  pt: "pt-BT",
  ru: "ru-RU",
  es: "es-ES",
  sw: "sw-SW",
  ur: "ur-UR",
  de: "de-DE",
  tr: "tr-TR",
  ro: "ro-RO",
  ta: "ta-TA",
  sv: "sv-SV",
  pl: "pl-PL",
  it: "it-IT",
  fi: "fi-FI",
  nl: "nl-NL",
  da: "da-DA",
  ms: "ms-MS",
  "en-AU": "en-AU",
  "en-GB": "en-GB",
};
export const CUSTOM_LANGUAGE_NAMES = {
  'zh-TW': "繁體中文",
  'zh-CN': "简体中文",
  'ar-AR': "عَرَبيْ",
  'my-MY': "မြန်မာစာ",
  'sw-SW': "Kiswahili",
  'ro-RO': "Românesc",
  'ta-TA': "தமிழ்",
  'pl-PL': "Polski",
  'fi-FI': "Suomi",
  'da-DA': "Dansk",
  'fr-FR': "Français",
  'hi-HI': "हिन्दी",
  'id-ID': "Bahasa Indonesia",
  'pt-BT': "Português",
  'ms-MS': "Bahasa Melayu",
  'en-AU': "English (Australia)",
  'en-GB': "English (United Kingdom)",
  'ja-KS': "Simple Japanese",
}